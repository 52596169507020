import { doAuth } from 'actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useFlags } from '../../showroom/use-flags';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateValue } from 'providers';
import { useTrack } from 'analytics/use-track';

export const CorporateLogin = () => {
  const hostedLogin = useFlags();
  const [{ session }, dispatch] = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();
  const { track } = useTrack();
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const ldClient = useLDClient();

  useEffect(() => {
    if (session.token) {
      const path =
        (location.state as any)?.from?.pathname !== '/' &&
        (location.state as any)?.from?.pathname !== '/login'
          ? (location.state as any)?.from?.pathname ?? '/'
          : '/';
      navigate(path, { replace: true });
      track({ subject: 'USER', action: 'LOGIN' });
    }
  }, [location, navigate, track, session]);

  useEffect(() => {
    if (isAuthenticated && hostedLogin) {
      getAccessTokenSilently().then(async (accessToken) => {
        const authAction = await doAuth(null, ldClient, {
          ...user,
          accessToken,
        });
        if (Array.isArray(authAction)) {
          authAction.forEach((action) => {
            dispatch(action);
          });
        } else {
          dispatch(authAction);
        }
      });
    } else {
      if (!isLoading && hostedLogin) {
        loginWithRedirect();
      }
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    ldClient,
    isAuthenticated,
    loginWithRedirect,
    hostedLogin,
    isLoading,
    error,
    user,
  ]);
  return null;
};
