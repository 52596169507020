import { AdminUsersNavigation } from './admin-users-navigation';
import { BrandUsersNavigation } from './brand-users-navigation';
import { CorporateUsersNavigation } from './corporate-users-navigation';
import { RetailUsersNavigation } from './retail-users-navigation';
import { useFlags } from '../../../../../showroom/use-flags';
import { useUserRoleGrouping } from 'hooks/use-user-role-grouping';
import React from 'react';

export const AppSideNavigation: React.FC = () => {
  const flags = useFlags();
  const userRoleGrouping = useUserRoleGrouping();

  if (userRoleGrouping === 'ADMIN') {
    return <AdminUsersNavigation />;
  }
  if (userRoleGrouping === 'BRAND') {
    return <BrandUsersNavigation />;
  }
  if (userRoleGrouping === 'CORPORATE') {
    return <CorporateUsersNavigation />;
  }
  if (userRoleGrouping === 'RETAIL' && flags.businessReviews) {
    return <RetailUsersNavigation />;
  }
  return null;
};
