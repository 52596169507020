import { BarChartSquare02, MessageQuestionSquare } from '../../../../icons';
import { Can } from 'providers';
import { Nav } from '../nav/nav';
import { NavItem } from '../nav-item/nav-item';
import { NavList } from '../nav-list/nav-list';
import { SubNavItem } from '../sub-nav-item/sub-nav-item';
import { useFlags } from '../../../../../showroom/use-flags';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const RetailUsersNavigation: React.FC = () => {
  const { t } = useTranslation('primary-template');
  const flags = useFlags();

  return (
    <Nav>
      <NavList>
        <Can I="get" a="admin:brands:analytics">
          <NavItem
            icon={<BarChartSquare02 />}
            label={t('sidebar.analytics')}
            to="/retail/brand-performance/brand-summary"
          />
        </Can>
        {flags.businessReviews ? (
          <NavItem
            icon={<MessageQuestionSquare />}
            label={t('sidebar.insights')}
          >
            <SubNavItem
              label={t('sidebar.activeInsights')}
              to="/retail/insights/active"
            />
            <SubNavItem
              label={t('sidebar.pastInsights')}
              to="/retail/insights/past"
            />
          </NavItem>
        ) : null}
      </NavList>
    </Nav>
  );
};
