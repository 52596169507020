import { AbilityProvider, StateProvider, ThemeProvider } from './';
import { Auth0Provider } from '@auth0/auth0-react';
import { initialState as baseState, Reducer as reducers } from 'reducers';

export const GlobalProvider = ({ children, initialState = baseState }) => {
  return (
    <ThemeProvider>
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/internal`,
        }}
      >
        <StateProvider initialState={initialState} reducer={reducers}>
          <AbilityProvider>{children}</AbilityProvider>
        </StateProvider>
      </Auth0Provider>
    </ThemeProvider>
  );
};
