import { getMostRecentlyConnectedShopify } from '../utils/get-most-recent-connected-shopify';
import { useGetBrand } from './api/brand/use-get-brand';

type ShopifyConnectionType = 'full' | 'n/a' | 'ro' | 'unknown' | undefined;

export function useBrandStatus() {
  const { data } = useGetBrand();

  const isContractSigned = !!data?.brand.brand_details?.contract_signed_status;

  const connectedShopify = getMostRecentlyConnectedShopify(
    data ? data.adapters : []
  );
  const isShopifyConnected = !!connectedShopify;
  const shopifyConnectionType: ShopifyConnectionType = connectedShopify
    ? (connectedShopify.state?.tier as ShopifyConnectionType)
    : undefined;

  const openStoresCount = data?.stores
    ? data?.stores.filter(
        (store) =>
          store.salesforce_store_stage === 'LIVE' ||
          store.salesforce_store_stage === 'STORE_CLOSURE'
      ).length
    : 0;

  const isAStoreOpened = openStoresCount > 0;

  return {
    connectedShopify,
    isAStoreOpened,
    isContractSigned,
    isShopifyConnected,
    shopifyConnectionType,
  };
}
