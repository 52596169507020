import { Box, Flex, Stack } from '@chakra-ui/react';
import { Button } from '../../components/forms/button/button';
import { Heading } from '../../components/typography/heading/heading';
import { Link } from '../../components/navigation/link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Text } from '../../components/typography/text';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ErrorTemplateProps {
  /**
   * Add `cover` when this template needs to cover the entire viewport.
   * Omit when using inside another template (ie - `PrimaryTemplate`).
   */
  cover?: boolean;
  /**
   * Text for the button.
   */
  ctaText?: string;
  /**
   * URL for the button. This also turns on the button.
   */
  ctaUrl?: string;
  /**
   * Help text to display below button or message.
   */
  helpText?: string;
  /**
   * Not required but encouraged as there is no fallback.
   */
  image?: React.ReactNode;
  /**
   * A supplemental message for the user.
   */
  message: string;
  /**
   * Describe the error (ie - "Not Found").
   */
  subtitle: string;
  /**
   * Really large `font-size` so keep it short (ie - HTTP error code).
   */
  title?: string;
}

export const ErrorTemplate: React.FC<ErrorTemplateProps> = ({
  cover,
  ctaText,
  ctaUrl,
  helpText,
  image,
  message,
  subtitle,
  title,
}) => {
  const { t } = useTranslation('error');
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      height={cover ? '100vh' : '100%'}
      overflow="hidden"
      p={8}
    >
      {image ? (
        <Flex align="center" justify="center" minH={40} pt={8}>
          {image}
        </Flex>
      ) : null}

      <Box
        alignItems="flex-start"
        display={{ base: 'static', md: 'flex' }}
        flexDirection="column"
        justifyContent="center"
        minW="50%"
        px={{ base: 0, md: 20 }}
        textAlign={{ base: 'center', md: 'left' }}
      >
        {title ? (
          <Heading
            as="h1"
            color="primary.600"
            fontSize="8.75rem"
            fontWeight="regular"
            mb={5}
            mt={6}
            size="none"
          >
            {title}
          </Heading>
        ) : null}

        <Heading
          as={title ? 'h2' : 'h1'}
          color="primary.600"
          fontSize="1.5rem"
          fontWeight="medium"
          size="none"
        >
          {subtitle}
        </Heading>

        <Text color="primary.600">{message}</Text>

        {ctaUrl ? (
          <Button as={ReactRouterLink} mb={4} to={ctaUrl}>
            {ctaText ? ctaText : t('goHome')}
          </Button>
        ) : null}

        {helpText ? (
          <Text fontSize="sm" color="primary.600">
            {helpText}{' '}
            <Link href={'mailto:support@leapinc.co'}>{t('help.link')}</Link>
          </Text>
        ) : null}
      </Box>
    </Stack>
  );
};
